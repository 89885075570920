import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSize(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/products/size/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSize(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("products/size", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteSize(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`products/size?id=${data.id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
